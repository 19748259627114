import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkHtml from 'remark-html';

export const applyOpenInNewTab = htmlStr =>
    htmlStr.split('<a ').join("<a target='_blank' rel='noopener noreferrer' ");

const httpsStr = 'https://';

export const replaceWWW = urlString => {
    if (urlString && !urlString.includes(httpsStr)) {
        return urlString.replace('www.', httpsStr);
    }
    return urlString;
};

export const convertToHtml = str => {
    if (str) {
        str = str.trim();
        return str.replace(/[\n\r]/g, '<br/>');
    } else {
        return '';
    }
};

export const convertMarkDownToHTML = postMarkDown => {
    if (postMarkDown) {
        let htmlData;
        htmlData = unified()
            .use(remarkParse)
            .use(remarkHtml)
            .processSync(postMarkDown);
        return htmlData;
    } else {
        return '<p>Missing Data</p>';
    }
};

/**
 * Method formatSourcesToHtml expects that URLs in Airtable under Additional Resources
 * Will be separated by new line character or carriage return key or both.
 * It basically considers the whole item as string and splits it using breaklines
 * @param source1
 * @param source2
 * @returns
 */
export const formatSourcesToHtml = (source1, source2) => {
    const source = convertToHtml((source1 ? source1 : '') + ' ' + (source2 ? source2 : ''));
    const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const regex = new RegExp(expression);
    const words = source.split('<br/>');
    const wordsWithLinks = words
        .filter(word => {
            let trimmedWord = word.trim();
            if (!trimmedWord || trimmedWord.length <= 0) {
                return false;
            } else if (trimmedWord === '<br/>') {
                return false;
            }
            return true;
        })
        .map((word, index) => {
            let trimmedWord = word.trim();
            if (trimmedWord.match(regex)) {
                trimmedWord = replaceWWW(word);
                const brTagExp = /<br\/>/g;

                //step1: replace the breaklines with emptstring
                let urlWithoutBrTag = trimmedWord.replace(brTagExp, '');

                //step2 build the anchor tag
                let anchorString =
                    "<a href='" +
                    urlWithoutBrTag +
                    `' target="_blank" rel="noopener noreferrer" >` +
                    urlWithoutBrTag +
                    '</a>';

                //step3: add the breaklines to front if found greater than zero
                anchorString = index === 0 ? anchorString : '<br/>'.repeat(2).concat(anchorString);
                return anchorString;
            }
            return word;
        });
    return wordsWithLinks.join(' ');
};
