import React, { useEffect } from 'react';

import { motion } from 'framer-motion';
import AddToGoals from '../../images/actions/userActionButtons/add-to-goals-modal-icon.svg';
import MarkAchieved from '../../images/actions/userActionButtons/mark-achieved-modal-icon.svg';
import NotForMe from '../../images/actions/userActionButtons/not-for-me-modal-icon.svg';

import '../../styles/actions.scss';

const modalInfo = {
    add: {
        icon: AddToGoals,
        header: 'Goal added',
        text: 'Congratulations on taking this step to a better life!',
        btn1: 'Review My Actions',
        btn2: 'Browse Actions',
    },
    done: {
        icon: MarkAchieved,
        header: 'Already achieved',
        text: 'We are so glad this is already part of your life!',
        btn1: 'Review My Actions',
        btn2: 'Browse Actions',
    },
    hide: {
        icon: NotForMe,
        header: 'Not for me',
        text: 'If you want to view this Action later, you can do so from the My Actions screen',
        btn1: 'Hide Action',
        btn2: 'Cancel',
    },
};

export default function ActionsModal({ modalOpen, setModalOpen, modalSelection }) {
    useEffect(() => {
        const handleEscapeKeyPress = e => {
            if (e.key === 'Escape') {
                setModalOpen(false);
            }
        };

        document.addEventListener('keydown', handleEscapeKeyPress, false);
        return () => document.removeEventListener('keydown', handleEscapeKeyPress, false);
    }, [setModalOpen]);

    const handleModalClick = e => {
        if (e.target.className === 'modal-background') {
            return setModalOpen(false);
        }
    };

    const populateModal = modalSelection => {
        return (
            <div className="modalContainer p-4">
                <div className="icon">
                    <img src={modalInfo[modalSelection].icon} alt="goal added" />
                </div>
                <h4>{modalInfo[modalSelection].header}</h4>
                <p>{modalInfo[modalSelection].text}</p>
                <div className="btns-container">
                    <button>{modalInfo[modalSelection].btn1}</button>
                    <button>{modalInfo[modalSelection].btn2}</button>
                </div>
            </div>
        );
    };

    return (
        modalOpen && (
            <section className="modal-background" onClick={handleModalClick}>
                <motion.dialog
                    initial={{
                        opacity: 0,
                        scale: 0.7,
                        y: 20,
                    }}
                    animate={{
                        scale: 1,
                        opacity: 1,
                        y: 0,
                    }}
                    transition={{
                        duration: 0.3,
                    }}
                    className="actions-modal shadow"
                    aria-modal="true"
                    open
                    aria-label="modal background"
                >
                    <button
                        className="btn-close"
                        aria-label="close"
                        onClick={() => setModalOpen(false)}
                    ></button>

                    {populateModal(modalSelection)}
                </motion.dialog>
            </section>
        )
    );
}
