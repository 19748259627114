import React from 'react';
import { motion } from 'framer-motion';
import { convertToHtml } from '../../utils/html-string-utils';

import DownloadAppButtons from '../DownloadAppButtons';

const ActFooter = ({ photoCreditFormatted }) => (
    <div className="categoryFooter">
        <br />
        <br />
        <br />
        <h2>Track actions</h2>
        <p>
            Want to keep track of the actions you are trying and have already completed? Want to
            sort by the different actions? Download the free mobile app for easier browsing and a
            personalized experience.
        </p>
        <motion.div
            initial={{
                opacity: 0,
                scale: 0.8,
                y: 20,
            }}
            animate={{
                scale: 1,
                opacity: 1,
                y: 0,
            }}
            transition={{
                duration: 1,
                delay: 0.4,
            }}
            className="app__downloads"
        >
            <DownloadAppButtons />
        </motion.div>
        {photoCreditFormatted ? (
            <>
                <br />
                <h6 className="photo-credit-heading">Photo Credit</h6>
                <div
                    className="photo-credit-text"
                    dangerouslySetInnerHTML={{
                        __html: convertToHtml(photoCreditFormatted),
                    }}
                />
            </>
        ) : null}
    </div>
);

export default ActFooter;
