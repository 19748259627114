import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Title from '../components/Title';
import ActionAttributes from '../components/Actions/ActionAttributes';
import ActionsGrid from '../components/Actions/ActionsGrid';
import ActFooter from '../components/Actions/ActFooter';
import ActionsModal from '../components/Modals/ActionsModal';
import UserActions from '../components/Actions/UserActions';

import { encodeCategoryURL } from '../utils/link-utils.js';
import { convertToHtml, formatSourcesToHtml } from '../utils/html-string-utils';
import { FirebaseContext } from '../utils/firebase-provider';

import '../styles/global.scss';
import '../styles/actions.scss';

const ActionPageTemplate = ({ pageContext: { actionDict, actionsRelated } }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSelection, setModalSelection] = useState('');
    const [heroCount, setHeroCount] = useState(0);
    const context = useContext(FirebaseContext);

    useEffect(() => {
        const retrieveActionsCount = async () => {
            const eachCompletedActionsCount = await context.getMetaData(
                'eachCompletedActionsCount'
            );
            setHeroCount(eachCompletedActionsCount[actionDict.ID]);
        };

        retrieveActionsCount();
    }, []);

    return (
        <>
            <ActionsModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                modalSelection={modalSelection}
            />
            <Layout>
                <SEO title={actionDict['Action Title']} />
                <section className="act section">
                    <div className="container pageHero pageHeroContent">
                        <div className="textBlock actionPage">
                            <Title>
                                <motion.h1
                                    initial={{
                                        opacity: 0,
                                        scale: 0.8,
                                        y: 20,
                                    }}
                                    animate={{
                                        scale: 1,
                                        opacity: 1,
                                        y: 0,
                                    }}
                                    transition={{
                                        duration: 1,
                                    }}
                                    className="pageHeadline"
                                >
                                    {actionDict['Action Title']}
                                </motion.h1>
                            </Title>
                            <div className="actionBreacrumbs">
                                <Link to="/act">
                                    <span>Actions</span>
                                </Link>
                                <span>{'>'}</span>
                                {actionDict['Category'].map(category => (
                                    <Link to={encodeCategoryURL(category)}>
                                        <span>{category}</span>
                                    </Link>
                                ))}
                            </div>
                            <img className="actionImg" src={actionDict['ImageUrl']} />
                            <ActionAttributes actionDict={actionDict} />

                            <div
                                className={`${
                                    context.status === 'logged' ? 'actionContainer' : ''
                                }`}
                            >
                                {'Summary' in actionDict && actionDict['Summary'] && (
                                    <div>
                                        <h2>Summary</h2>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: convertToHtml(actionDict['Summary']),
                                            }}
                                        />
                                        <br />
                                    </div>
                                )}
                                <div>
                                    <h2>Description</h2>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: convertToHtml(actionDict['Description']),
                                        }}
                                    />
                                    <br />
                                </div>
                                {context.status === 'logged' && (
                                    <React.Fragment>
                                        <div className="actionBtnsContainer">
                                            {/* TODO: if "Goal Added" use component that shows buttons for "Mark Achieved" & "Cancel" */}
                                            <UserActions
                                                setModalOpen={setModalOpen}
                                                setModalSelection={setModalSelection}
                                                modalOpen={modalOpen}
                                                heroCount={heroCount}
                                            />
                                        </div>
                                        <br />
                                    </React.Fragment>
                                )}
                            </div>
                            {actionDict['Tips'] ? (
                                <React.Fragment>
                                    <h2>Tips</h2>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: convertToHtml(actionDict['Tips']),
                                        }}
                                    />
                                    <br />
                                </React.Fragment>
                            ) : null}
                            {actionDict['Additional Resources (optional)'] ? (
                                <React.Fragment>
                                    <h2>Additional Resources</h2>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: formatSourcesToHtml(
                                                actionDict['Additional Resources (optional)']
                                            ),
                                        }}
                                    />
                                    <br />
                                </React.Fragment>
                            ) : null}
                            <hr />
                            {actionsRelated && actionsRelated.length > 0 ? (
                                <React.Fragment>
                                    <h2>Related</h2>
                                    <ActionsGrid actions={actionsRelated} />
                                </React.Fragment>
                            ) : null}
                            <ActFooter
                                photoCreditFormatted={actionDict['Photo Credit (formatted)']}
                            />
                        </div>
                        {
                            // TODO: ADD:
                            // types of impact, needs to be included in graphql
                            // tags, needs airtable transform
                        }
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default ActionPageTemplate;
