import React, { useMemo } from 'react';

import '../../styles/actions.scss';

export default function UserActions({ setModalOpen, setModalSelection, modalOpen, heroCount }) {
    return (
        <>
            {/* NOTE: include SVG to change colours on hover; cannot be done if wrapped in img */}
            <button
                className="actionBtns"
                onClick={() => {
                    setModalSelection('add');
                    setModalOpen(!modalOpen);
                }}
            >
                <div className="btnText">
                    <svg
                        className="actionIcon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="24" height="24" rx="12" fill="#717171" />
                        <path d="M12 6V18V6Z" fill="white" />
                        <path d="M12 6V18" stroke="white" stroke-width="1.5" />
                        <path d="M6 12H18H6Z" fill="white" />
                        <path d="M6 12H18" stroke="white" stroke-width="1.5" />
                    </svg>
                    Add to my goals
                </div>
            </button>
            <button
                className="actionBtns"
                onClick={() => {
                    setModalSelection('done');
                    setModalOpen(!modalOpen);
                }}
            >
                <div className="btnText">
                    <svg
                        className="actionIcon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="24" height="24" rx="12" fill="#717171" />
                        <path
                            d="M6 12.4545L9.63636 17L17.8182 7"
                            stroke="#F1F0F0"
                            stroke-width="1.5"
                        />
                    </svg>
                    Already achieved
                </div>
            </button>
            <button
                className="actionBtns"
                onClick={() => {
                    setModalSelection('hide');
                    setModalOpen(!modalOpen);
                }}
            >
                <div className="btnText">
                    <svg
                        className="actionIcon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="24" height="24" rx="12" fill="#717171" />
                        <path d="M17 17L7 7" stroke="#F1F0F0" stroke-width="1.2" />
                        <path d="M17 7L7 17" stroke="#F1F0F0" stroke-width="1.2" />
                    </svg>
                    Not for me
                </div>
            </button>
            <p className="infoText">
                {/* conditionally render count of heroes */}
                <span>{heroCount} heroes</span> have already done this action! <span>😄</span>
            </p>
        </>
    );
}
